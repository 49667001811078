.App {
    border: 2px solid #d3d3d3;
    border-radius: .5em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    padding: 1em;
    text-align: left;
    width: 600px;
}

.form {
    padding: 1em;
}

label {
    font-weight: 600;
}